import * as React from 'react';
import {Component} from 'react';
import {WithTranslation} from 'react-i18next';
import {ApiRequest, Language, Test, Training, TrainingModule} from '../api/Api';
import {
    Avatar,
    Button, CircularProgress,
    createMuiTheme,
    FormControl,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    MuiThemeProvider,
    Select, TablePagination, Typography,
    WithTheme
} from '@material-ui/core';
import {WsConnectionManager} from '../misc/WsConnectionManager';
import moment, {duration, Moment} from 'moment';
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import AccountAvatar from '@material-ui/icons/AccountCircle';
import NoAnswerIcon from '@material-ui/icons/PanoramaFishEye';
import GoodAnswerIcon from '@material-ui/icons/Done';
import WrongAnswerIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh';

interface GroupsStatsProps extends WithTranslation, WithTheme {
    lang: Language;
    wsManager: WsConnectionManager;
    modules?: TrainingModule[];
    tests?: Test[];
    training: Training;
    from: Moment;
    to: Moment;
}

interface GroupsStatsState {
    selectedModule: string;
    bulkResults: any[];
    visibleResults: any[];
    ready: boolean;
    currentPage: number;
    currentRowsPerPage: number;
    panelFrom: Moment;
    panelTo: Moment;
    timeSpanSelectValue: number;
    mode: 'manual' | 'select';
    stats: Map<string, number>;
}

export default class GroupsStats extends Component<GroupsStatsProps, GroupsStatsState> {


    constructor(props: GroupsStatsProps) {
        super(props);
        this.state = {
            selectedModule: this.props.modules[0].key,
            bulkResults: [],
            visibleResults: [],
            ready: false,
            currentPage: 0,
            currentRowsPerPage: 10,
            panelFrom: moment().subtract(60, "minutes"),
            panelTo: moment(),
            timeSpanSelectValue: 60,
            mode: 'select',
            stats: new Map()
        };
    }

    componentDidMount(): void {
        this.retrieveDataAndSet();
    }

    private retrieveDataAndSet() {
        this.getBulkResults(results => {

            let sums = new Map<string, number>();
            let hits = new Map<string, number>();

            results.forEach(result =>{
               result.results.forEach((testResult: any) => {
                   let localKey = result.moduleKey + '_' +testResult.testKey;
                  let sum = sums.get(localKey) | 0;
                  let count = hits.get(localKey) | 0;
                  if(testResult.hasWin) {
                      sum++;
                  }
                  count++;
                  sums.set(localKey, sum);
                  hits.set(localKey, count);
               });
            });

            let stats = new Map<string, number>();
            Array.from(hits.keys()).forEach(testKey => {
                let sum = sums.get(testKey);
                let count = hits.get(testKey);
                stats.set(testKey, Math.round((sum/count)*100));
            });


            let firstVisibleIndex = this.state.currentPage * this.state.currentRowsPerPage;
            let lastIndex = firstVisibleIndex + this.state.currentRowsPerPage;

            this.setState({
                bulkResults: results,
                visibleResults: results.slice(firstVisibleIndex, lastIndex),
                stats: stats,
                ready: true
            });
        });
    }

    private getBulkResults(cb: (res: any[]) => void) {
        let request = {
            action: 'db-bulk-results-raw-get',
            parameters: {
                from: this.state.panelFrom.unix(),
                to: this.state.panelTo.unix(),
                moduleKey: this.state.selectedModule
            }
        } as ApiRequest;
        this.props.wsManager.request(request, response => {
            if (response['status'] !== 'ok') {
                console.error('Could not retrieve bulkResults', response);
                cb(null);
            } else {
                cb((response.result as any[]))
            }
        });
    }

    private dateChanged() {
        if (this.state.mode === 'select') {
            this.setState({
                panelFrom: moment().subtract(this.state.timeSpanSelectValue, "minutes"),
                panelTo: moment(),
            }, () => this.retrieveDataAndSet());
        } else {
            this.retrieveDataAndSet()
        }
    }

    private onPageChange(pageNumber: number) {

        let firstVisibleIndex = pageNumber * this.state.currentRowsPerPage;
        let lastIndex = firstVisibleIndex + this.state.currentRowsPerPage;

        this.setState({
            currentPage: pageNumber,
            visibleResults: this.state.bulkResults.slice(firstVisibleIndex, lastIndex)
        });

    }

    private onRowsPerPageChange(rowsPerPage: number) {

        let firstVisibleIndex = 0;

        this.setState({
            currentPage: 0,
            currentRowsPerPage: rowsPerPage,
            visibleResults: this.state.bulkResults.slice(firstVisibleIndex, rowsPerPage)
        });

    }

    render() {

        let content;
        if (this.state.ready == false) {
            content = <CircularProgress value={-1}/>;
        } else {

            if (this.state.visibleResults.length == 0) {
                content = <span style={{fontSize: '2em', lineHeight: '6em'}}>{this.props.t('Groups.noResults')}</span>
            } else {

                let testOfModule = this.props.tests.filter(test => test.moduleKey === this.state.selectedModule).sort((t1,t2)=>(t1.key as string).localeCompare(t2.key as string));

                let statsLine;
                if(this.state.bulkResults.length > 0) {
                    statsLine = <tr>
                        <td><Typography variant={'body1'}>{this.props.t('Groups.success')}</Typography></td>
                        {testOfModule.map(test => {
                            return <td><Typography variant={'body1'}>{this.state.stats.get(test.key)}%</Typography></td>
                        })}
                    </tr>;
                }


                content =
                    <table style={{width: "100%"}}>
                        <thead>
                        <tr>
                            <td/>
                            {testOfModule.map((test) => {
                                return <td key={test.key}>
                                    <Typography variant={'body1'}>{test.name[this.props.lang]}</Typography>
                                </td>
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.visibleResults.map(record => {
                            return <tr key={record.runId}>
                                <td>
                                    <Avatar style={{width: '20px', height: '20px', marginLeft: 'auto', marginRight: 'auto'}}>
                                        <AccountAvatar fontSize='small'/>
                                    </Avatar>
                                </td>
                                {testOfModule.map(test => {
                                    let result = record.results.find((answer: any) => answer.testKey === (test.key as string).split('_')[1]);
                                    if (result == null) {
                                        return <td key={test.key} style={{
                                            backgroundColor: 'grey',
                                            color: 'white',
                                            textAlign: 'center',
                                            lineHeight: '0px'
                                        }}><NoAnswerIcon/></td>
                                    } else {
                                        return <td key={test.key} style={{
                                            backgroundColor: result.hasWin ? 'green' : 'red',
                                            color: 'white',
                                            textAlign: 'center',
                                            lineHeight: '0px'
                                        }}>
                                            {result ? <GoodAnswerIcon/> : <WrongAnswerIcon/>}
                                        </td>
                                    }
                                })}
                            </tr>;
                        })}
                        {statsLine}
                        </tbody>
                    </table>;
            }
        }

        return <Grid container direction="column">
            <Grid item>
                <MuiThemeProvider theme={createMuiTheme({
                    typography: {
                        htmlFontSize: 10,
                        fontFamily: 'Open Sans',
                        allVariants: {
                            color: '#7b838b'
                        }
                    }
                })}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={this.props.lang}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item>
                                <FormControl>
                                    <InputLabel>{this.props.t('Matricules.scenario')}</InputLabel>
                                    <Select
                                        id="scenarioSelect"
                                        value={this.state.selectedModule}
                                        onChange={(e) => this.setState({
                                            selectedModule: e.target.value as string
                                        }, () => this.retrieveDataAndSet())}
                                    >
                                        {this.props.modules.map(module => <MenuItem key={module.key} value={module.key}>{module.name[this.props.lang]}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <DateTimePicker ampm={false} label={this.props.t('Actions.from')}
                                                format="DD/MM/YYYY HH:mm" value={this.state.panelFrom}
                                                onChange={(newDate: any) => this.setState({
                                                    panelFrom: newDate,
                                                    mode: 'manual'
                                                }, () => this.dateChanged())}/>
                            </Grid>
                            <Grid item>
                                <DateTimePicker ampm={false} label={this.props.t('Actions.to')}
                                                format="DD/MM/YYYY HH:mm" value={this.state.panelTo}
                                                onChange={(newDate: any) => this.setState({
                                                    panelTo: newDate,
                                                    mode: 'manual'
                                                }, () => this.dateChanged())}/>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel>{this.props.t('Actions.last',{context:'female'})}</InputLabel>
                                    <Select
                                        id="timeSpanSelect"
                                        value={this.state.timeSpanSelectValue}
                                        onChange={(e) => this.setState({
                                            timeSpanSelectValue: Number(e.target.value),
                                            mode: 'select'
                                        }, () => this.dateChanged())}
                                    >
                                        <MenuItem value={60}>{moment.duration(1, 'hours').locale(this.props.lang).humanize()}</MenuItem>
                                        <MenuItem value={120}>{moment.duration(2, 'hours').locale(this.props.lang).humanize()}</MenuItem>
                                        <MenuItem value={180}>{moment.duration(3, 'hours').locale(this.props.lang).humanize()}</MenuItem>
                                        <MenuItem value={240}>{moment.duration(4, 'hours').locale(this.props.lang).humanize()}</MenuItem>
                                        <MenuItem value={720}>{moment.duration(12, 'hours').locale(this.props.lang).humanize()}</MenuItem>
                                        <MenuItem value={1440}>{moment.duration(24, 'hours').locale(this.props.lang).humanize()}</MenuItem>
                                        <MenuItem value={2880}>{moment.duration(48, 'hours').locale(this.props.lang).humanize()}</MenuItem>
                                        <MenuItem value={10080}>{moment.duration(168, 'hours').locale(this.props.lang).humanize()}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => this.dateChanged()}><RefreshIcon
                                    fontSize="large"/></IconButton>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </Grid>
            <Grid item
                  style={{width: '100%', overflow: 'auto', minHeight: '30vh', marginTop: '20px', textAlign: 'center'}}>
                {content}
            </Grid>
            <Grid item container justify={'center'} style={{marginTop: '20px'}}>
                <Grid item>
                    <TablePagination
                        labelRowsPerPage={this.props.t('Matricules.MatriculesList.rowsPerPage')}
                        component='div'
                        page={this.state.currentPage}
                        rowsPerPage={this.state.currentRowsPerPage}
                        count={this.state.bulkResults.length}
                        onChangePage={(event, pageNumber) => this.onPageChange(pageNumber)}
                        onChangeRowsPerPage={(event) => this.onRowsPerPageChange(Number(event.target.value))}
                    />
                </Grid>
            </Grid>
        </Grid>;

    }
}